<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-06 15:47:15
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-02-06 16:06:08
 * @FilePath: /mediatom-web/src/views/aggregate/Config/components/SkipContralModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="skipContralVisible"
    title="跳过控制"
    @onCancel="handleCancel"
    @ok="handleSubmit"
  >
    <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateSource } from '@/api/aggregate'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      formquery: {},
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sourceInfo: {
      default: () => ({}),
      type: Object
    },
    baseQuery: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {},
      deep: true,
      immediate: true
    }
  },
  computed: {
    skipContralVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.skipContralVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await updateSource(this.formquery)
          if (resp.code === 200) {
            this.$message.success('修改成功!')
            this.$emit('changeSuccess')
            this.skipContralVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style></style>
